<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div>
        <b-button
          v-if="true"
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="$router.push({name: 'home-orders-purchase-cr-list'})"
        >
          {{ $t('Back to List') }}
        </b-button>
        <b-button
          v-else
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
        >
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t('Cancel') }}
        </b-button>
      </div>
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <!--   Void Handler     -->
        <b-button
          style="width: 120px; height: 40px; background-color: #6F1214 !important;"
          class="font-medium-1 font-weight-bold d-flex align-items-center justify-content-center"
          :disabled="loadingFor.isLoading || isQuoteVoided"
          @click="submit(RFQ_SENT_ACTION_VOID)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === RFQ_SENT_ACTION_VOID.action"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(RFQ_SENT_ACTION_VOID.title) }}
          </span>
        </b-button>
        <!--   Change RFQ Handler     -->
        <b-button
          :disabled="loadingFor.isLoading"
          variant="secondary"
          class="font-medium-1 font-weight-bold d-flex align-items-center justify-content-center"
          style="width: 170px; height: 40px;"
          @click="redirectToQuoteFromSupplier(quote.id, RFQ_SENT_ACTION_CHANGE_RFQ.action)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === RFQ_SENT_ACTION_CHANGE_RFQ.action"
            small
            variant="secondary"
          />
          <div v-else>
            <feather-icon icon="LLoadIcon" />
            {{ $t(RFQ_SENT_ACTION_CHANGE_RFQ.title) }}
          </div>
        </b-button>
        <!--   Print Handler     -->
        <b-button
          :disabled="loadingFor.isLoading"
          variant="secondary"
          class="font-medium-1 font-weight-bold px-3"
          @click="printRFQForSupplierTemplate"
        >
          <Printer>
            <div
              id="rfq"
              hidden
            >
              <RFQPrintTemplate />
            </div>
          </Printer>
          <feather-icon icon="LPrintIcon" />
          {{ $t(RFQ_SENT_ACTION_BTN_PRINT.title) }}
        </b-button>
        <!--   RFQ_SENT_ACTION_SAVE_AS_DRAFT Handler     -->
        <b-button
          v-if="quoteAsDraft"
          disabled
          variant="secondary"
          class="font-medium-1 font-weight-bold px-3"
        >
          {{ $t(RFQ_SENT_ACTION_SAVE_AS_DRAFT.title) }}
        </b-button>
        <!--   Send Again Handler     -->
        <b-button
          v-if="!quoteAsDraft"
          :disabled="loadingFor.isLoading"
          variant="secondary"
          class="font-medium-1 font-weight-bold d-flex align-items-center justify-content-center"
          style="width: 170px; height: 40px;"
          @click="submit(RFQ_SENT_ACTION_SEND_AGAIN)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === RFQ_SENT_ACTION_SEND_AGAIN.action"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(RFQ_SENT_ACTION_SEND_AGAIN.title) }}
          </span>
        </b-button>

        <!--   Send by Email Handler     -->
        <b-button
          v-if="quoteAsDraft"
          :disabled="loadingFor.isLoading"
          variant="secondary"
          class="font-medium-1 font-weight-bold d-flex align-items-center justify-content-center"
          style="width: 170px; height: 40px;"
          @click="submit(RFQ_SENT_BY_EMAIL)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === RFQ_SENT_BY_EMAIL.action"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(RFQ_SENT_BY_EMAIL.title) }}
          </span>
        </b-button>

        <!--   Quote Received Handler     -->
        <b-button
          :disabled="loadingFor.isLoading || isQuoteVoided"
          variant="success"
          class="font-medium-1 font-weight-bold d-flex align-items-center justify-content-center"
          style="width: 170px; height: 40px;"
          @click="submit(RFQ_SENT_ACTION_QUOTE_RECEIVED)"
        >
          <b-spinner
            v-if="loadingFor.isLoading && loadingFor.action === RFQ_SENT_ACTION_QUOTE_RECEIVED.action"
            small
            variant="secondary"
          />
          <span v-else>
            {{ $t(RFQ_SENT_ACTION_QUOTE_RECEIVED.title) }}
          </span>
        </b-button>
      </div>
    </div>
  </portal>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import Printer from '@/views/components/Printer/Printer.vue'
import config from './config'
import mainConfig from '../../../config'
import RFQPrintTemplate from './components/RFQPrintTemplate.vue'

export default {
  name: 'FormBottom',
  components: {
    BButton,
    Printer,
    BSpinner,
    RFQPrintTemplate,
  },
  data() {
    return {
     loadingFor: {
         isLoading: false,
         action: null,
       },
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
    isQuoteVoided() {
      return this.quote.status === this.ORDERS_STATUS_REJECTED
    },
    quoteAsDraft() {
      return this.$store.state[this.MODULE_NAME].quote.status === this.ORDERS_STATUS_AS_DRAFT
    },
    isQuoteVoid() {
      return this.quote.state === 0 && this.quote.status === 5
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    redirectToQuoteFromSupplier(id, action) {
      if (this.RFQ_SENT_ACTION_SEND_AGAIN.action === action) return false

      if (this.RFQ_SENT_BY_EMAIL.action === action) {
        this.$router.push({ name: 'home-orders-purchase-rfq-for-supplier', params: { id } })
      } else if (this.RFQ_SENT_ACTION_CHANGE_RFQ.action === action) {
        this.$router.push({ name: 'direct-request-for-quote', params: { id } })
      } else if (this.RFQ_SENT_ACTION_VOID.action === action) {
        this.$router.push({ name: 'home-orders-purchase-cr-list' })
      } else {
        this.$router.push({ name: 'home-orders-purchase-register-received-quote-from-supplier', params: { id } })
      }
    },
    printRFQForSupplierTemplate() {
      this.$htmlToPaper('rfq', {
        autoClose: false,
      })
    },
    submit(item) {
      const { id } = this.quote
      const { action, successMsg } = item

       this.loadingFor = {
         isLoading: true,
         action,
       }

      this.sendNotification(this, { id, body: { id, action } }, `${this.MODULE_NAME}/patch`, successMsg)
        .then(({ data }) => {
          // eslint-disable-next-line no-shadow
          const { id } = data.data

          this.redirectToQuoteFromSupplier(id, action)
        }).finally(() => {
           this.loadingFor = {
             isLoading: false,
             action: null,
           }
        })
    },
  },
  setup() {
    const {
      RFQ_SENT_BY_EMAIL,
      RFQ_SENT_ACTION_VOID,
      RFQ_SENT_ACTION_SEND,
      RFQ_SENT_ACTION_BACK_RFQ,
      RFQ_SENT_ACTION_BTN_PRINT,
      RFQ_SENT_ACTION_CHANGE_RFQ,
      RFQ_SENT_ACTION_SEND_AGAIN,
      RFQ_SENT_ACTION_REJECT_QUOTE,
      RFQ_SENT_ACTION_SAVE_AS_DRAFT,
      RFQ_SENT_ACTION_QUOTE_RECEIVED,
      RFQ_SENT_ACTION_BACK_RFQ_DRAFT,
    } = config()

    const {
      MODULE_NAME,
      ORDERS_STATUS_REJECTED,
      ORDERS_STATUS_AS_DRAFT,
    } = mainConfig()

    return {
      MODULE_NAME,
      RFQ_SENT_BY_EMAIL,
      RFQ_SENT_ACTION_VOID,
      RFQ_SENT_ACTION_SEND,
      ORDERS_STATUS_AS_DRAFT,
      ORDERS_STATUS_REJECTED,
      RFQ_SENT_ACTION_BACK_RFQ,
      RFQ_SENT_ACTION_BTN_PRINT,
      RFQ_SENT_ACTION_CHANGE_RFQ,
      RFQ_SENT_ACTION_SEND_AGAIN,
      RFQ_SENT_ACTION_REJECT_QUOTE,
      RFQ_SENT_ACTION_SAVE_AS_DRAFT,
      RFQ_SENT_ACTION_QUOTE_RECEIVED,
      RFQ_SENT_ACTION_BACK_RFQ_DRAFT,
    }
  },
}
</script>
