<template>
  <div>
    <div v-if="quote.id">
      <portal to="body-top">
        <h3 class="text-center font-weight-bolder mb-1">
          {{ $t(RFQ_TITLE_PREFIX) }}
          {{ getValueFromGivenObjectByKey(quote, 'id') }}
          {{ $t('for') }}
          {{ getValueFromGivenObjectByKey(quote, 'supplier.cust_name_dba') }}
          —
          {{
            $t(quote.status === ORDERS_STATUS_AS_DRAFT ? RFQ_TITLE_SUFFIX_DRAFT : quote.status === ORDERS_STATUS_REJECTED ? RFQ_TITLE_SUFFIX_VOIDED : RFQ_TITLE_SUFFIX_SENT)
          }}
        </h3>
      </portal>
      <order-information
        class="mb-1"
        :quote="quote"
      />
      <stock-items
        class="mb-1"
        :quote="quote"
      />
      <order-information-bottom :quote="quote" />
      <form-bottom />
    </div>

    <div v-else>
      <skeleton v-if="isLoading && !errorStatus" />
      <div v-else-if="errorStatus && !isLoading">
        <component-not-found
          :error-status="errorStatus"
          :title="PAGE_TITLE"
          :button-link="{name: 'home-orders-purchase-cr-list'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import OrderInformation from '../components/create/rfq-for-supplier/OrderInformation.vue'
import StockItems from '../components/create/rfq-for-supplier/StockItems.vue'
import OrderInformationBottom from '../components/create/rfq-for-supplier/OrderInformationBottom.vue'
import FormBottom from '../components/create/rfq-for-supplier/FormBottom.vue'
import Skeleton from '../components/create/rfq-for-supplier/components/Skeleton.vue'
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'RFQForSupplier',
  components: {
    Skeleton,
    StockItems,
    FormBottom,
    OrderInformation,
    OrderInformationBottom,
  },
  data() {
    return {
      errorStatus: null,
      isLoading: false,
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  created() {
    this.isLoading = true
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})

    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params?.id)
      .catch(({ response: { status } }) => {
        this.errorStatus = status
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  setup() {
    const {
      RFQ_TITLE_PREFIX,
      RFQ_TITLE_SUFFIX_SENT,
      RFQ_TITLE_SUFFIX_DRAFT,
      ORDERS_STATUS_REJECTED,
      ORDERS_STATUS_AS_DRAFT,
      MODULE_NAME, PAGE_TITLE,
      RFQ_TITLE_SUFFIX_VOIDED,
    } = config()

    return {
      PAGE_TITLE,
      MODULE_NAME,
      RFQ_TITLE_PREFIX,
      RFQ_TITLE_SUFFIX_SENT,
      ORDERS_STATUS_REJECTED,
      ORDERS_STATUS_AS_DRAFT,
      RFQ_TITLE_SUFFIX_DRAFT,
      RFQ_TITLE_SUFFIX_VOIDED,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
